<template>
  <div class="mainwrap bannerlist" v-loading="loading">
    <div class="title">
      品牌核銷查詢
    </div>
    <div class="searchform">
      <el-form
          label-width="82px"
          :inline="true"
          :model="searchForm"
          class="demo-form-inline"
      >
        <el-form-item label="查詢類型：">
          <el-select v-model="searchForm.SelectType" placeholder="请选择查詢類型" @change="changeType">
            <el-option
                v-for="item in SelectTypeList"
                :key="item.key"
                :label="item.value"
                :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="券名：">
          <el-input v-model="searchForm.Name" placeholder="請輸入名稱"></el-input>
        </el-form-item>
        <el-form-item label="活動編號：">
          <el-input v-model="searchForm.No" placeholder="請輸入活動券代碼"></el-input>
        </el-form-item>
        <el-form-item label="品牌：">
          <el-select v-model="searchForm.BrandId" clearable placeholder="请选择品牌" @change="changeBrand">
            <el-option
                v-for="item in brandDropList"
                :key="item.key"
                :label="item.value"
                :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店別：" class="shopWrap">
          <div style="display: flex;">
            <el-input
              v-model="searchForm.shopListText"
              disabled
              placeholder="請選擇店別"
          ></el-input>
            <el-button style="margin-left: 10px;" type="primary" :disabled="isMore" @click="openShopDialog">選擇</el-button>
          </div>
        </el-form-item>
        <el-form-item label="時間：">
          <el-date-picker
              :editable="false"
              v-model="searchForm.Date"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="開始時間"
              end-placeholder="結束時間"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet">重置</el-button>
          <el-button type="success" :disabled="disabledDown" @click="downLoad">導出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe v-if="searchForm.SelectType === 1">
        <el-table-column prop="brand" label="品牌"> </el-table-column>
        <el-table-column prop="counter" label="店別"> </el-table-column>
        <el-table-column prop="no" label="活動編號" width="100"> </el-table-column>
        <el-table-column prop="name" label="券名"> </el-table-column>
        <el-table-column prop="publishNum" label="發行張數"> </el-table-column>
        <el-table-column prop="receiveNum" label="領取張數"> </el-table-column>
        <el-table-column prop="writeoffNum" label="核銷張數"> </el-table-column>
        <el-table-column prop="createTime" label="創建時間"> </el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button @click="lookDetail(scope.row)" type="info">
              明細
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" style="width: 100%" stripe v-if="searchForm.SelectType === 2">
        <el-table-column prop="no" label="活動編號" width="100"> </el-table-column>
        <el-table-column prop="name" label="券名"> </el-table-column>
        <el-table-column prop="brand" label="品牌"> </el-table-column>
        <el-table-column prop="counter" label="店別"> </el-table-column>
        <el-table-column prop="publishNum" label="發行張數"> </el-table-column>
        <el-table-column prop="receiveNum" label="領取張數"> </el-table-column>
        <el-table-column prop="writeoffNum" label="核銷張數"> </el-table-column>
        <el-table-column prop="createTime" label="創建時間"> </el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button @click="lookDetail(scope.row)" type="info">
              明細
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="tableDataTotal"
      >
      </el-pagination>
    </div>

    <el-dialog title="明細" :visible.sync="dialogTableVisible">
      <el-button style="margin-bottom: 20px;" type="success" :disabled="disabledDetailDown" @click="downLoadDetail">導出</el-button>
      <div class="tablewrap">
        <el-table :data="detailData" style="width: 100%" stripe v-if="searchForm.SelectType === 1">
          <el-table-column prop="brand" label="品牌"> </el-table-column>
          <el-table-column prop="counter" label="店別"> </el-table-column>
          <el-table-column prop="no" label="活動編號" width="100"> </el-table-column>
          <el-table-column prop="name" label="券名"> </el-table-column>
          <el-table-column prop="code" label="券號"> </el-table-column>
          <el-table-column  prop="receiveTime" label="領取時間"></el-table-column>
          <el-table-column prop="usedTime" label="核銷時間"></el-table-column>
          <el-table-column prop="member" label="會員名稱"></el-table-column>
          <el-table-column prop="paymentAmount" label="消費金額"></el-table-column>
        </el-table>
        <el-table :data="detailData" style="width: 100%" stripe v-if="searchForm.SelectType === 2">
          <el-table-column prop="no" label="活動編號" width="100"> </el-table-column>
          <el-table-column prop="name" label="券名"> </el-table-column>
          <el-table-column prop="code" label="券號"> </el-table-column>
          <el-table-column prop="brand" label="品牌"> </el-table-column>
          <el-table-column prop="counter" label="店別"> </el-table-column>
          <el-table-column  prop="receiveTime" label="領取時間"></el-table-column>
          <el-table-column prop="usedTime" label="核銷時間"></el-table-column>
          <el-table-column prop="member" label="會員名稱"></el-table-column>
          <el-table-column prop="paymentAmount" label="消費金額"></el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination
            background
            :hide-on-single-page="detailPage.isSingle"
            layout="prev, pager, next,jumper"
            @current-change="handleDetailCurrentChange"
            :page-size="detailPage.size"
            :current-page="detailPage.current"
            :total="detailPage.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!--選擇店別-->
    <el-dialog
        title="選擇店別"
        @close="hideShopDialog"
        :visible.sync="shopDialog.show"
        width="40%"
    >
      <div class="searchBox">
        <el-input v-model="shopDialog.search"></el-input>
        <el-button type="primary" @click="searchShop">搜索</el-button>
      </div>
      <el-table
          :data="shopData"
          @selection-change="handleSelectionChange"
          row-key="key"
          ref="multipleTable"
      >
        <el-table-column
            type="selection"
            width="100"
            :reserve-selection="true"
        ></el-table-column>
        <el-table-column prop="value" label="名稱"></el-table-column>
      </el-table>
      <div class="bottom">
        <div style="margin-top: 20px">
          <el-button type="primary" @click="submitShopDialog">確定</el-button>
          <el-button @click="hideShopDialog">取消</el-button>
        </div>
        <div class="pagination">
          <el-pagination
              background
              :hide-on-single-page="shopPager.isSingle"
              layout="prev, pager, next,jumper"
              @current-change="changeShopPage"
              :page-size="shopPager.size"
              :current-page="shopPager.current"
              :total="shopPager.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "./api";
import QS from "qs";
import { getStore} from '@/utils/storage';
import { tableDataPagination } from "@/utils/common";
import {brandBieList, exportBrandWriteOffList} from "./api";
export default {
  name: "index",
  data() {
    return {
      dialogTableVisible:false,
      isMore: false,
      loading:false,
      searchForm: {
        SelectType: 1,
        Name: "",
        No: "",
        Date: "",
        BrandId: "",
        CounterId: [],
        sorting: '',
        shopListText: "", // 店別名稱
      },
      brandDropList: [],
      isSinglePage: false,
      pageSize:10,
      currentPage: 1,
      rowCounterId: null,
      tableData: [],
      detailData: [],
      tableDataTotal:0,
      visible:false,
      permissionType: -1,
      // 店別
      shopData: [],
      shopDialog: {
        show: false,
        search: "",
      },
      shopPager: {
        isSingle: false,
        size: 10,
        current: 1,
        total: 0,
      },
      detailPage: {
        isSingle: false,
        size: 10,
        current: 1,
        total: 0,
      },
      SelectTypeList:[{
        key: 1,
        value: "品牌"
      },{
        key: 2,
        value: "優惠券"
      }],
      checkTips: false,
      allRestaurantList: [],
      disabledDown: true,
      disabledDetailDown:true,
      activityId: ''
    };
  },
  methods: {
    //導出列表
    downLoad(){
      this.disabledDown = true;
      let params={
        selectType: this.searchForm.SelectType,
        no: this.searchForm.No,
        startTime: this.searchForm.Date?this.searchForm.Date[0] + " " + "00:00:00":'',
        endTime: this.searchForm.Date?this.searchForm.Date[1] + " " + "23:59:59":'',
        brandId: this.searchForm.BrandId.toString(),
        name: this.searchForm.Name,
        sorting: this.searchForm.sorting,
        counterId: this.searchForm.CounterId,
        skipCount:(this.currentPage-1)*this.pageSize,
        maxResultCount:this.pageSize
      }
      for (let key in params){
        if(params[key]===""){
          delete params[key];
        }
      }
      this.loading = true;
      api.exportBrandWriteOffList(params).then(res=>{
        if(res.systemCode===200){
          window.open(res.data,"_blank")
        }
        this.loading=false;
      })
    },
    //導出明細
    downLoadDetail(){
      this.disabledDetailDown=true;
      let params={
        ActivityId: this.activityId,
        Name: this.searchForm.Name,
        No: this.searchForm.No,
        StartTime:this.searchForm.Date[0] ? this.searchForm.Date[0] + " " + "00:00:00" : '',
        EndTime:this.searchForm.Date[1] ? this.searchForm.Date[1] + " " + "23:59:59" : '',
        Sorting: this.searchForm.sorting,
        BrandId: this.searchForm.BrandId.toString(),
        CounterId: this.rowCounterId ? this.rowCounterId : null,
        SkipCount: (this.detailPage.current-1)*this.detailPage.size,
        MaxResultCount: this.detailPage.size
      }
      for (let key in params){
        if(params[key]===""){
          delete params[key];
        }
      }
      console.log(params)
      this.loading = true;
      api.exportBrandWriteOffDetailList(params).then(res=>{
        if(res.systemCode===200){
          window.open(res.data,"_blank")
        }
        this.disabledDetailDown = false;
        this.loading = false;
      })
    },
    // getBrandList
    getBrandList(){
      this.loading=true;
      let params={
        SelectType: this.searchForm.SelectType,
        Name:this.searchForm.Name,
        No: this.searchForm.No,
        StartTime:this.searchForm.Date[0] ? this.searchForm.Date[0] + " " + "00:00:00" : '',
        EndTime:this.searchForm.Date[1] ? this.searchForm.Date[1] + " " + "23:59:59" : '',
        Sorting: this.searchForm.sorting,
        BrandId: this.searchForm.BrandId,
        CounterId: this.searchForm.CounterId.length>0 ? this.searchForm.CounterId : '',
        SkipCount:(this.currentPage-1)*this.pageSize,
        MaxResultCount:this.pageSize
      }
      for (let key in params){
        if(params[key]==="" || params[key]?.length === 0){
          delete params[key];
        }
      }
      // 拼接參數
      let paramsStr="";
      for (let key in params){
          if(params[key]!=="" && key!=="CounterId"){
              paramsStr+=`${key}=${params[key]}&`;
          }
      }
      // 拼接CounterId:[1,2] => CounterId=1&CounterId=2
      if(params.CounterId!==""){
          paramsStr+=QS.stringify({CounterId: params.CounterId}, { arrayFormat: 'repeat' })
      }else{
          paramsStr=paramsStr.slice(0,-1);
      }
      api.getBrandList(paramsStr).then(res=>{
        if(res.systemCode===200){
          this.tableData=res.data.items;
          if(this.tableData.length>0){
            this.disabledDown = false
          }
          this.tableDataTotal=res.data.totalCount;
        }
        this.loading=false;
      })
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage=val;
      this.getBrandList();
    },
    handleDetailCurrentChange(val){
      this.detailPage.current = val;
      this.getDetailList();
    },
    // 查詢
    onSearch() {
      this.currentPage=1;
      if(this.searchForm.SelectType === 1){
        if(this.searchForm.BrandId == '' && (this.searchForm.CounterId?.length === 0 || this.searchForm.CounterId == '')){
          this.$message.error('按查詢品牌，品牌為必填');
          return;
        }
      }else{
        if(this.searchForm.Name == '' && this.searchForm.No == ''){
          this.$message.error('按優惠券查詢，券名或活動編號須填其中一項');
          return;
        }
      }
      this.getBrandList();
    },
    reSet(){
      this.currentPage=1;
      this.searchForm={
        SelectType: 1,
        Name: "",
        No: "",
        Date: "",
        BrandId: this.brandDropList[0]?.key,
        CounterId: [],
        sorting: '',
        shopListText: "", // 店別名稱
      }
      this.getBrandList();
    },
    //獲取品牌下拉列表
    getBrandDropList(){
      api.brandBieList({}).then(res=>{
        if(res.systemCode===200){
          this.brandDropList = res.data;
          this.searchForm.BrandId = this.brandDropList[0]?.key;
          this.getShopData();
          this.getBrandList();
        }
      })
    },
    // 獲取店別列表
    getShopData() {
      let params = {};
      if (this.searchForm.BrandId) params.BrandId = this.searchForm.BrandId;
      this.allRestaurantList = [];
      this.isMore = true;
      this.shopVisible = false;
      api.getCounterList(params).then((res) => {
        if (res.systemCode === 200) {
          this.allRestaurantList = res.data;
        }
        this.isMore = false;
      });
    },
    //切換查詢類別
    changeType(){
      if(this.searchForm.SelectType === 2){
        this.searchForm.BrandId = ''
        this.searchForm.CounterId = []
      }else{
        this.searchForm.BrandId = this.brandDropList[0]?.key
      }
    },
    // 切換品牌
    changeBrand(e) {
      this.checkTips = true;
      // 清空店別
      this.searchForm.CounterId = [];
      this.searchForm.shopListText = "";
      // 獲取店別
      this.getShopData();
    },
    // 打開店別彈窗
    openShopDialog() {
      this.shopPager.current = 1;
      this.getPageRes();
      this.shopDialog.show = true;
      // 清空全選
      this.$nextTick(() => {
        if (this.$refs.multipleTable) {
          if (this.searchForm.shopListText === "") {
            this.$refs.multipleTable.clearSelection();
          } else if (this.searchForm.CounterId.length > 0) {
            this.searchForm.CounterId.forEach((key) => {
              const List = this.allRestaurantList.find(
                  (item) => item.key == key
              );
              this.$refs.multipleTable.toggleRowSelection(List, true);
            });
          }
        }
      });
    },
    // 取消彈窗
    hideShopDialog() {
      this.shopDialog.show = false;
      this.shopDialog.search = "";
      this.$refs.multipleTable.clearSelection();
    },
    // 確認店別彈窗
    submitShopDialog() {
      this.checkTips = true;
      this.shopDialog.show = false;
      const idArr = [];
      this.searchForm.shopListText = "";
      if (this.multipleSelection.length > 0) {
        let ctStr = "";
        this.multipleSelection.forEach((item) => {
          ctStr += item.value + "，";
          this.searchForm.CounterId.push(item.key);
        });
        this.searchForm.shopListText = ctStr.slice(0, -1);
      }
    },
    // 多選店別
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 切換店別分頁
    changeShopPage(val) {
      this.shopPager.current = val;
      this.getPageRes();
    },
    // 搜索店別
    searchShop() {
      this.shopPager.current = 1;
      this.getPageRes();
    },
    // 處理表格數據
    getPageRes() {
      const res = tableDataPagination(
          this.allRestaurantList,
          this.shopPager.current,
          this.shopPager.size,
          this.shopDialog.search
      );
      this.shopData = res.dataList;
      this.shopPager.total = res.dataTotal;
    },
    //查看明細
    lookDetail(item){
      this.activityId = item.activityId;
      this.rowCounterId = item.counterId
      this.getDetailList();
    },
    //獲取明細列表
    getDetailList(){
      this.disabledDetailDown=true;
      let params={
        ActivityId: this.activityId,
        Name: this.searchForm.Name,
        No: this.searchForm.No,
        StartTime:this.searchForm.Date[0],
        EndTime:this.searchForm.Date[1],
        Sorting: this.searchForm.sorting,
        BrandId: this.searchForm.BrandId,
        CounterId: this.rowCounterId ? this.rowCounterId : null,
        SkipCount: (this.detailPage.current-1)*this.detailPage.size,
        MaxResultCount: this.detailPage.size
      }
      for (let key in params){
        if(params[key]==="" || params[key]?.length === 0){
          delete params[key];
        }
      }
      api.brandWriteOffDetailList(params).then(res=>{
        if(res.systemCode===200){
          this.dialogTableVisible = true
          this.detailData=res.data.items;
          this.detailPage.total=res.data.totalCount;
          this.loading=false;
          if(this.detailData.length>0){
            this.disabledDetailDown=false;
          }
        }
      })
    }
  },
  created(){
    this.getBrandDropList();
    this.permissionType = parseInt(getStore("permissionType"));
  },
  activated(){
    this.getBrandList();
  }
};
</script>

<style lang="scss" scoped>
.bannerlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 100px;
    float: right;
  }
  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }

}

.searchBox {
  width: 500px;
  margin-bottom: 10px;
  .el-input {
    width: 250px;
    margin-right: 10px;
  }
}
</style>
